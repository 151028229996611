import { Avatar, Button, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useMutation } from '@tanstack/react-query';
import createAuthRequest from './utils/createAuthRequest';
import { Seller } from './models/seller';
import { StyledMenuLink } from './components/StyledLink';
import vendoraLogo from './icons/vendora-logo.png';
import { useSnackbar } from './utils/error-toast-provider';

interface Props {
  existingEmail?: string;
  onEmailUpdated: () => void;
}

export const VendoraEmailForm = ({ existingEmail, onEmailUpdated }: Props) => {
  const { showSnackbar } = useSnackbar();
  const updateSellerMutation = useMutation({
    mutationFn: (email: string) => createAuthRequest<Seller>('sellers/current/external-ids', 'PUT')(undefined, undefined, {
      platform: 'vendora',
      email
    }),
    onSuccess: () => {
      showSnackbar('Имейл адресът беше записан', 'success');
      onEmailUpdated();
    }
  })

  const validationSchema = object().shape({
    email: string()
      .email('Невалиден имейл адрес')
      .required('Имейл адресът е задължителен'),
  });

  const formik = useFormik({
    initialValues: { email: existingEmail ?? '' },
    validationSchema,
    onSubmit: (values) => updateSellerMutation.mutate(values.email),
  });

  return <Stack direction='column' alignItems='center' padding={3} gap={3}>
    <Avatar
      src={vendoraLogo}
      variant='rounded'
      sx={{ width: 50, height: 50 }}
    />
    <DialogTitle variant="h6">Свързване на Vendora профил</DialogTitle>
    <Typography alignSelf='start' variant="body2">За да свържеш профила си във Vendora, моля въведи имейл адреса, с който си регистриран там.</Typography>
    <Typography alignSelf='start' variant="body2">Ако все още нямаш профил във Vendora, регистрирай се <StyledMenuLink to={'https://vendora.bg/login'}>тук</StyledMenuLink></Typography>

    <Typography alignSelf='start' variant="body2">След като получим потвърждение от Vendora, ще можеш да управляваш обявите си там, използавйки expanda. Потвърждението отнема не повече от 24 часа.</Typography>
    <Stack direction='column' gap={1} width='75%'>
      <TextField
        title='Имейл във Vendora'
        label='Имейл във Vendora'
        name='email'
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && !!formik.errors.email}
        helperText={formik.touched.email && formik.errors.email}
      />
      <Button
        type='submit'
        name='submit'
        variant='contained'
        color='success'
        onClick={() => formik.handleSubmit()}
        disabled={!formik.touched.email || !formik.isValid || formik.values.email === existingEmail}
      >
        {existingEmail ? 'Редактирай' : 'Свържи'}
      </Button>
    </Stack>
  </Stack >
};
