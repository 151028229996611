import { Box } from '@mui/material';
import { List, InfiniteLoader, WindowScroller, AutoSizer } from 'react-virtualized';

type Props<T> = {
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  items: T[];
  loadNextPage: (startIndex: number, stopIndex: number) => Promise<void>;
  skeleton: React.ReactNode;
  row: (item: T) => React.ReactNode;
  rowHeight: (index: number) => number;
}

export const InfiniteList = <T,>({ hasNextPage, isNextPageLoading, items, loadNextPage, skeleton, row, rowHeight }: Props<T>) => {
  const rowCount = hasNextPage ? items.length + 1 : items.length;

  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  return (
    <Box sx={{ width: '100%' }}>
      <InfiniteLoader
        isRowLoaded={({ index }) => isItemLoaded(index)}
        rowCount={rowCount}
        loadMoreRows={({ startIndex, stopIndex }) => isNextPageLoading ? Promise.resolve() : loadNextPage(startIndex, stopIndex)}
        threshold={2}
      >
        {({ onRowsRendered, registerChild }) => (
          <WindowScroller>
            {({ height, isScrolling, scrollTop }) => (
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    autoHeight
                    height={height}
                    width={width}
                    isScrolling={isScrolling}
                    scrollTop={scrollTop}
                    rowCount={rowCount}
                    rowHeight={(i) => rowHeight(i.index)}
                    onRowsRendered={onRowsRendered}
                    ref={registerChild}
                    rowRenderer={({ index, key, style }) =>
                      <div key={key} style={{ ...style, width: '100%' }}>
                        {!isItemLoaded(index) ? skeleton : row(items[index])}
                      </div>}
                  />
                )}
              </AutoSizer>
            )}
          </WindowScroller>
        )}
      </InfiniteLoader>
    </Box>
  );
}