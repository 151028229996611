import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { useEffect, useState } from 'react';
import { Paper, Box, Snackbar, Stack, Typography, CircularProgress } from '@mui/material';
import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { auth } from './App';
import { useNavigate } from 'react-router-dom';
import createAuthRequest from './utils/createAuthRequest';
import { useMutation } from '@tanstack/react-query';
import { useAuthState } from 'react-firebase-hooks/auth';

export default function Login() {
  const [token, setToken] = useState<string | undefined>();
  const [signInAllowed, setSignInAllowed] = useState(true);
  const navigate = useNavigate();

  const login = async () => {
    const response = await createAuthRequest<string | undefined>('sellers', 'POST')();
    if (!response) {
      await auth.signOut();
      setSignInAllowed(false);
      return;
    }
    navigate('/');
  }

  const createSellerMutation = useMutation({ mutationFn: login });

  useEffect(() => {
    if (!!token) {
      createSellerMutation.mutate(undefined);
    }
  }, [token]);

  const config = () => ({
    signInOptions: [
      {
        provider: EmailAuthProvider.PROVIDER_ID,
      },
      {
        provider: GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          prompt: 'select_account'
        }
      }
    ],
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    callbacks: {
      signInSuccessWithAuthResult: (authResult: any) => {
        setToken(authResult.credential?.idToken || authResult.user?.accessToken);
        return false;
      }
    }
  });

  const [authState, loading] = useAuthState(auth);

  useEffect(() => {
    var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    ui.start('#firebaseui-auth-container', config());
  }, []);

  return <Stack>
    {loading && <CircularProgress />}
    <>
      {!loading && authState && navigate('/')}
    </>
    <Typography variant='h6' component='h1' textAlign='center'>
      Добре дошъл в expanda! Влез с един от посочените методи, за да продължиш!
    </Typography>
    <Paper sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Box id='firebaseui-auth-container'></Box>
    </Paper>
    <Snackbar
      open={!signInAllowed}
      autoHideDuration={6000}
      message='Неуспешен вход'
    />
  </Stack>
}