import { useState } from 'react';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import createAuthRequest from './utils/createAuthRequest';
import { Box, Button, Checkbox, CircularProgress, Fab, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Switch, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RequestError } from './utils/request-error';
import { useSnackbar } from './utils/error-toast-provider';
import { Add } from '@mui/icons-material';
import { PaginatedResponse } from './models/paginated-response';
import { LoadingWrapper } from './components/LoadingWrapper';

export type OlxListingForImport = {
  id: number;
  title: string;
  titleImageUrl: string;
  price: number;
  priceUnit: string;
};

export const ImportPage = () => {
  const [selectedIds, setSelectedIds] = useState<Record<number, boolean>>({});

  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const handleSelectItem = (itemId: number) => {
    setSelectedIds((prevSelectedIds) => {
      const isSelected = prevSelectedIds[itemId];
      return { ...prevSelectedIds, [itemId]: !isSelected };
    });
  };

  const fetchListingsForImport = async ({ pageParam = 0 }): Promise<PaginatedResponse<OlxListingForImport>> => {
    const emptyResponse: PaginatedResponse<OlxListingForImport> = { data: [], offset: undefined };
    try {
      const items = await olxItemsRequest(undefined, [['page', pageParam.toString()]]);
      return items ?? emptyResponse;
    } catch (e) {
      throw new RequestError(500, 'Не успяхме да заредим обявите за импорт');
    }
  };

  const olxItemsRequest = createAuthRequest<PaginatedResponse<OlxListingForImport>>('listings/marketplaces/olx', 'GET');

  const importListings = async (ids: number[]) => {
    const request = createAuthRequest('listings/marketplaces/olx', 'POST');
    return request(undefined, undefined, ids);
  }

  const mutation = useMutation({
    mutationFn: importListings,
    onSuccess: response => {
      if (!!response) {
        showSnackbar('Обявите бяха успешно импортирани', 'success');
        navigate('/');
      }
    },
  });

  const toggleSelectAll = (selected: boolean) => {
    setSelectedIds(Object.fromEntries(
      data?.pages.flatMap(({ data }) => data.map(({ id }) => [id, selected])) ?? []));
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isError
  } = useInfiniteQuery({
    queryKey: ['olx-items'],
    initialPageParam: 0,
    queryFn: fetchListingsForImport,
    getNextPageParam: ({ offset }) => offset
  });

  const hasListingsForImport = () => data?.pages?.some((page) => page?.data?.length > 0) ?? false;

  return (
    <Stack alignItems={{ xs: 'center', sm: 'start' }} spacing={2} paddingLeft={{ xs: 0, sm: 2 }}>
      <Typography variant='h5' textAlign='center'>
        Добави обявите си от OLX в expanda
      </Typography>
      <Typography variant='body2' textAlign='center'>
        Избери тези, които искаш да добавиш. След това ще можеш лесно да ги качиш в други платформи.
      </Typography>
      {!isFetching && hasListingsForImport() &&
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Fab variant='extended'>
            <Typography variant='caption'>Избери всички</Typography>
            <Switch color='success' onChange={(_, checked) => toggleSelectAll(checked)}></Switch>
          </Fab>
          <LoadingWrapper loading={mutation.isPending || isFetching}>
            <Fab
              variant='extended'
              onClick={() => mutation.mutate(Object.keys(selectedIds).map(Number))}
              color='success'
              disabled={!Object.values(selectedIds).some(id => id)}
            >
              <Add sx={{ mr: 1 }}></Add>
              <Typography variant='caption'>Добави избраните</Typography>
            </Fab>
          </LoadingWrapper>
        </Stack>
      }

      {
        isError && <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Typography>
            Грешка при извличането на обяви от OLX
          </Typography>
        </Box>
      }

      {!isFetching && !hasListingsForImport() && <>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Typography>
            Няма намерени обяви за импорт
          </Typography>
        </Box>
      </>}

      {isFetching && !data && <>
        <Box
          display='flex'
          flexDirection={'column'}
          justifyContent='center'
          alignItems='center'
        >
          <Typography>
            Търсим обяви за импорт
          </Typography>
          <CircularProgress color='primary' />
        </Box>
      </>}

      {
        data && <>
          <List>
            {data && data.pages.map((page) =>
              page && page.data?.map(({ id, title, titleImageUrl, price, priceUnit }) => (
                <ListItem disablePadding={true} key={id}>
                  <ListItemButton role={undefined} onClick={() => handleSelectItem(id)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedIds[id] ?? false}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': id.toString() }}
                      />
                    </ListItemIcon>
                    <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'center', sm: 'start' }}>
                      <img alt='Снимка на обява' src={titleImageUrl} width={200} />
                      <ListItemText sx={{ paddingLeft: 2 }} id={id.toString()} primary={title} secondary={`${price}${priceUnit}`} />
                    </Stack>
                  </ListItemButton>
                </ListItem>
              ))
            )}
          </List>
          {hasNextPage && !isFetching && (
            <Button
              variant='contained'
              color='primary'
              disabled={isFetching}
              onClick={() => fetchNextPage()}>
              Зареди още
            </Button>
          )}
          {
            isFetching && data &&
            <CircularProgress />
          }
        </>
      }
    </Stack >
  );
};
export default ImportPage;