import { useEffect } from 'react';
import { auth } from './App';
import { useNavigate } from 'react-router-dom';

export const LogoutPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    auth.signOut();
    navigate('/login');
  }, []);

  return null;
};