import { Autocomplete, TextField } from '@mui/material'
import createAuthRequest from './utils/createAuthRequest';
import { TreeItem } from './models/tree';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { FormFields } from './models/main-form-fields';

type Props = {
  endpoint: string;
  preselectedLocationId?: string;
  formField: 'city' | 'district';
  locationTypeLabel: string;
};

export const LocationSelector = ({ endpoint, formField, locationTypeLabel, preselectedLocationId }: Props) => {
  const [locations, setLocations] = useState<TreeItem[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<TreeItem | undefined>();
  const formikContext = useFormikContext<FormFields>();

  const locationsResponse = useQuery({
    queryKey: [endpoint],
    queryFn: () => createAuthRequest<TreeItem[]>(endpoint, 'GET')()
  });

  const handleChange = (value: { optionLabel: string; id: string } | null) => {
    const selected = locations.find(location => location.id === value?.id);
    setSelectedLocation(selected);
    formikContext.setFieldValue(formField, selected?.id);
  }

  useEffect(() => {
    setLocations(locationsResponse.data ?? []);
    const preselectedCity = locationsResponse.data?.find(item => item.id === preselectedLocationId);
    setSelectedLocation(preselectedCity);
    formikContext.setFieldValue(formField, preselectedCity?.id);
  }, [locationsResponse.data, preselectedLocationId]);

  return locations && locations.length > 0 ?
    <Autocomplete
      disablePortal
      options={locations.map(({ name, id }) => ({
        optionLabel: name,
        id
      }))}
      value={selectedLocation ? {
        optionLabel: selectedLocation.name,
        id: selectedLocation.id
      } : null}
      onChange={(_, value) => handleChange(value)}
      getOptionLabel={(option) => option.optionLabel}
      getOptionKey={(option) => option.id}
      sx={{ flex: 1, width: '100%' }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) =>
        <TextField
          label={locationTypeLabel}
          error={formikContext.touched[formField] && !!formikContext.errors[formField]}
          helperText={formikContext.touched[formField] && formikContext.errors[formField]}
          {...params}
        />}
    /> : <></>;
}