import { AlertColor } from '@mui/material';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';

export const getErrorHandlingQueryClient = (showSnackbar: (message: string, severity: AlertColor) => void) => new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) => {
      showSnackbar(error.message, 'error');
    }
  }),
  mutationCache: new MutationCache({
    onError: (error: any) => {
      showSnackbar(error.message, 'error');
    }
  })
});