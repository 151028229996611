import { Autocomplete, Box, InputAdornment, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { AttributeDeclaration, DiscreteValue, isNumericAttribute, isInputAttribute, isSelectAttribute, isMultiSelectAttribute, isSingleSelectAttribute, SingleDiscreteValue, DiscreteValues } from './models/category-specific-attribute'
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { FormFields } from './models/main-form-fields';

type Props = {
  attribute: AttributeDeclaration;
  fieldValueUpdated: (attributeId: string, value: OutputType) => void;
  initialValue?: OutputType;
};

type InputType = string | DiscreteValue | DiscreteValues;
export type OutputType = string | string[] | undefined;

export const AttributeField = (
  { attribute, fieldValueUpdated, initialValue }: Props
) => {
  const getInitialValue = (attribute: AttributeDeclaration): string | DiscreteValue | DiscreteValues | null => {
    if (isInputAttribute(attribute) || isNumericAttribute(attribute)) {
      return '';
    }
    if (isMultiSelectAttribute(attribute)) {
      return [];
    }
    return null;
  };

  const formik = useFormikContext<FormFields>();

  const [value, setValue] = useState<string | DiscreteValue | DiscreteValues | null | undefined>(
    getInitialValue(attribute)
  );

  // Pre-fill initial value
  useEffect(() => {
    const getDiscreteValue = (value: OutputType): DiscreteValue | undefined => {
      if (!isSelectAttribute(attribute)) {
        return;
      }
      if (Array.isArray(value)) {
        return attribute.options.filter(v => value.includes(v.code));
      }
      return attribute.options.find(v => v.code === value);
    }
    if (!initialValue) {
      return;
    }
    if (isInputAttribute(attribute) || isNumericAttribute(attribute)) {
      setValue(initialValue as string);
    }
    const discreteValue = getDiscreteValue(initialValue);
    if (!!discreteValue) {
      setValue(discreteValue);
    }
  }, [initialValue, attribute]);

  const onValueUpdated = (newValue: string | DiscreteValue | DiscreteValues) => {
    setValue(newValue);
    fieldValueUpdated(attribute.code, getUpdatedValue(newValue));
  };

  const getUpdatedValue = (newValue: InputType): OutputType => {
    if (!newValue) {
      return undefined;
    }
    if (isInputAttribute(attribute) || isNumericAttribute(attribute)) {
      return newValue as string;
    }
    if (isSingleSelectAttribute(attribute)) {
      return (newValue as SingleDiscreteValue).code;
    }
    return (newValue as DiscreteValues).map(v => v.code);
  }

  const hasManyOptions = isSelectAttribute(attribute) && attribute.options.length > 8;
  const hasFewOptions = isSelectAttribute(attribute)
    && attribute.options.length <= 8
    && attribute.options.length > 0;

  return (
    <>
      {(isNumericAttribute(attribute) || isInputAttribute(attribute)) &&
        <TextField
          type={attribute.type === 'number' ? 'number' : 'text'}
          required={attribute.required}
          placeholder={attribute.label}
          sx={{ width: '100%', marginTop: '20px' }}
          name={attribute.label}
          InputProps={{
            endAdornment: <InputAdornment position='end'>{attribute.unit}</InputAdornment>
          }}
          value={value}
          error={!!formik.errors.attributes?.[attribute.code]}
          helperText={formik.errors.attributes?.[attribute.code]}
          onChange={(event) => onValueUpdated(event.target.value)}
        />
      }
      {hasManyOptions &&
        <Autocomplete
          disablePortal
          value={value as SingleDiscreteValue | DiscreteValues}
          options={attribute.options ?? []}
          getOptionLabel={(value) => value.label}
          multiple={isMultiSelectAttribute(attribute)}
          getOptionKey={(value) => value.code || ''}
          sx={{ width: '100%' }}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          renderInput={(params) =>
            <TextField
              {...params}
              key={params.id}
              label={attribute.label}
              error={!!formik.errors.attributes?.[attribute.code]}
              helperText={formik.errors.attributes?.[attribute.code]}
            />}
          onChange={(_, value) => onValueUpdated(value)}
        />
      }
      {hasFewOptions &&
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', width: '100%', gap: '5px' }}>
          <Typography>{attribute.label}</Typography>
          <ToggleButtonGroup
            color="primary"
            value={value}
            title={attribute.label}
            size='small'
            sx={{ width: '100%' }}
            exclusive={isSingleSelectAttribute(attribute)}
            onChange={(_, value) => { onValueUpdated(value); }}
          >
            {attribute.options.map(op =>
              <ToggleButton sx={{ width: `${(100 / attribute.options.length)}%`, wordBreak: 'break-all' }} key={op.code} value={op}>{op.label}</ToggleButton>
            )}
          </ToggleButtonGroup>
          {formik.errors.attributes?.[attribute.code] &&
            <Box>
              <Typography variant='caption' color='error'>
                {formik.errors.attributes?.[attribute.code]}
              </Typography>
            </Box>
          }
        </Box>
      }
    </>
  )
}