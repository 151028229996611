import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router-dom';
import { auth } from './App';
import { AuthenticatedUserContext } from './contexts/authenticatedUserContext';

export const AuthenticatedRoute = ({ children }: { children: any }) => {
  const [user, loading] = useAuthState(auth);

  if (!!loading) {
    return <div>Loading...</div>;
  }

  return !!user ?
    <AuthenticatedUserContext>
      {children}
    </AuthenticatedUserContext> :
    <Navigate to="/login" />;
};
