import { Box, Button, SxProps, Theme, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';
import { FormFields, VendoraFormFields } from './models/main-form-fields';

interface FormSubmitButtonProps {
  onClick: () => void;
  title: string;
}

export const FormSubmitButton = <T extends VendoraFormFields | FormFields,>({ onClick, title }: FormSubmitButtonProps) => {
  const { errors, isValid } = useFormikContext<T>();

  const getErrorsTooltipContent = (): string | undefined => {
    if (isValid) {
      return;
    }
    return Object.values(errors).map(error => {
      if (typeof error === 'object') {
        return Object.values(error).join('\n');
      }
      return error;
    }).join('\n');
  };

  const submitButton = (sx: SxProps<Theme>) => <Button
    type='submit'
    name='submit'
    variant='contained'
    color='success'
    sx={sx}
    disabled={!isValid}
    onClick={onClick}
  >
    {title}
  </Button>;

  return isValid ?
    submitButton({ flex: 1 }) :
    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{getErrorsTooltipContent()}</span>}>
      <Box sx={{ flex: 1 }}>
        {submitButton({ width: '100%' })}
      </Box>
    </Tooltip>;
};
