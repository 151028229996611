import { Box, Button, ButtonGroup, Stack, Typography } from '@mui/material';
import { Tree, Node, getSubtree } from './models/tree';
import { useEffect, useState } from 'react';
import { ArrowBack } from '@mui/icons-material';

type TreeItem = {
  id: string,
  parent_id?: string;
  name: string;
};

export type Props = {
  tree: Tree<TreeItem>
  onItemSelected: (node: Node<TreeItem>) => void,
  selectedCategory: Node<TreeItem> | undefined,
  selectedCategoryLabel: string
}

/**
 * Menu-like navigation for a given tree structure
 * @param props the input tree and selection handler
 */
export const TreeVisualizer = ({ tree, onItemSelected, selectedCategory, selectedCategoryLabel }: Props) => {
  const [currentRootNode, setCurrentRootNode] = useState<Node<TreeItem> | undefined>(selectedCategory);
  const [selectedNode, setSelectedNode] = useState<Node<TreeItem> | undefined>(selectedCategory);

  // Important for the following two effects to be in this order, as otherwise the preselected category will be overwritten
  useEffect(() => {
    setCurrentRootNode(tree.root);
  }, [tree]);

  useEffect(() => {
    if (!!selectedCategory) {
      setSelectedNode(selectedCategory);
      setCurrentRootNode(selectedCategory);
    }
  }, [selectedCategory]);

  const handleNodeClick = (node: Node<TreeItem>) => {
    // Navigate to the child
    if (!!node.children && Object.values(node.children).length > 0) {
      setCurrentRootNode(node);
      return;
    }
    // Select the leaf
    setSelectedNode(node);
    onItemSelected(node);
  };

  const hasParent = (node: Node<TreeItem> | undefined): boolean => node?.data.parent_id !== undefined;

  const handleBackClick = () => {
    setSelectedNode(undefined);
    setCurrentRootNode(getSubtree(tree, currentRootNode?.data?.parent_id)?.root);
  };

  const getParentCategory = () => getSubtree(tree, currentRootNode?.data.parent_id)?.root.data.name;

  return <>
    {
      !!selectedNode &&
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
        <Typography>Избрана {selectedCategoryLabel}: {selectedNode.data.name}</Typography>
        <Button variant='outlined' color={'secondary'} onClick={handleBackClick}>
          Промени
        </Button>
      </Box>
    }
    {
      !selectedNode &&
      <Stack direction={{ xs: 'column', sm: 'row' }}>
        {hasParent(currentRootNode) &&
          <Button sx={{ flex: 1 }} disabled={!hasParent(currentRootNode)} onClick={handleBackClick}>
            <ArrowBack></ArrowBack>
            {getParentCategory()}
          </Button>
        }
        <ButtonGroup orientation='vertical' sx={{ flex: 3 }}>
          {currentRootNode?.data?.name && <Button disabled>
            {currentRootNode.data.name}
          </Button>
          }
          {Object.values(currentRootNode?.children ?? {}).map((child) =>
            <Button
              key={child.root.data.id}
              onClick={() => handleNodeClick(child.root)}
            >
              {child.root.data?.name}
            </Button>
          )}
        </ButtonGroup>
      </Stack>
    }
  </>
};