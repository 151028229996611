interface GenericImage {
  fileName: string;
  order: number;
};

export interface LocalImage extends GenericImage {
  data: File;
};

export const isLocalImage = (image: RemoteImage | LocalImage): image is LocalImage =>
  (image as LocalImage).data !== undefined;

export interface RemoteImage extends GenericImage {
  url: string;
};

export type Image = LocalImage | RemoteImage;
