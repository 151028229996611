import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import { useCallback, useState } from 'react';
import { FilePondFile, FileStatus } from 'filepond';
import { RemoteImage, Image } from './models/image';
import { ImagePreviewDialog } from './ImagePreviewDialog';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageValidateSize
);

type Props = {
  existingImages: RemoteImage[];
  imagesChanged: (existingImages: Image[]) => void;
  disableEdit?: boolean;
  listingId?: string;
  imagesUpdateRequired?: boolean;
  imagesUpdated?: () => void;
};

/**
 * Image input component - supports drag&drop, reordering, image previews and deletion
 * @param existingImages - existing images - in the case of editing
 * @param imagesChanged - callback whenever a change happens in the image input
 */
export const ImageInput = ({ existingImages, imagesChanged, listingId, disableEdit = false, imagesUpdated, imagesUpdateRequired }: Props) => {
  /**
  * @param file - the file changed (FilePond object)
  * @param index - the index of the file in the list (important for ordering)
  * @returns either an LocalFile or a RemoteFile
  */
  const [filepondRef, setFilepondRef] = useState<FilePond | null>();

  const [previewDialogImageUrl, setPreviewDialogImageUrl] = useState<string | null>(null);

  const createImage = ({ source, filename }: FilePondFile, index: number): Image => {
    return {
      fileName: typeof source === 'string' ? existingImages.find(image => image.url === source)?.fileName || filename : filename,
      order: index,
      // Include either an URL to the image (in case of a downloaded image)
      // or the image data itself (in case of a local image)
      ...(typeof source === 'string' ?
        { url: source } :
        { data: new File([source], source.name, { type: source.type }) })
    };
  };

  // Updating images on both update & reorder
  // Updates can be either an addition or a removal of a file
  const updateImages = useCallback((files: FilePondFile[]) => {
    imagesChanged(files.filter(
      file => ![FileStatus.LOAD_ERROR, FileStatus.PROCESSING_ERROR, FileStatus.PROCESSING_REVERT_ERROR].includes(file.status))
      .map(createImage));
  }, []);

  const addFiles = (files: RemoteImage[]) => {
    filepondRef?.addFiles(files
      .sort((a, b) => a.order - b.order)
      .map(image => image.url));
  }

  const removeFiles = () => {
    filepondRef?.removeFiles();
  }

  if (imagesUpdateRequired && !!imagesUpdated) {

    removeFiles();
    addFiles(existingImages);
    imagesUpdated();
  }

  return <>
    <FilePond
      acceptedFileTypes={['image/*']}
      maxFileSize={'5MB'}
      labelMaxFileSizeExceeded='Максималният размер за снимка е 5MB'
      labelFileTypeNotAllowed='Позволени са само изображения'
      labelMaxFileSize=''
      onupdatefiles={updateImages}
      onreorderfiles={updateImages}
      oninit={() => addFiles(existingImages)}
      imagePreviewHeight={200}
      allowMultiple={true}
      maxFiles={12}
      allowReorder={true}
      allowDrop={true}
      // Important, as otherwise FilePond will try to upload the files to a nonexistent server
      instantUpload={false}
      labelIdle='Постави снимки или <span class="filepond--label-action">избери локални</span>'
      disabled={disableEdit}
      onactivatefile={(file) => setPreviewDialogImageUrl(file.source.toString())} // Hook for clicking a file
      imageValidateSizeMaxWidth={4096}
      imageValidateSizeMaxHeight={4096}
      ref={setFilepondRef} />

    <ImagePreviewDialog
      isOpen={!!previewDialogImageUrl}
      onClose={() => setPreviewDialogImageUrl(null)}
      existingImageUrls={existingImages.map(image => image.url)}
      initialSelectedImageUrl={previewDialogImageUrl || ''}
      listingId={listingId ?? ''}
    />
  </>
}