import { useNavigate } from 'react-router-dom';
import createAuthRequest from './utils/createAuthRequest';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Stack, Typography, Switch, Fab, Avatar, Dialog, Box, useMediaQuery, Theme, TextField } from '@mui/material';
import ListingItemSummary, { ListingItemSummarySkeleton } from './components/ListingItemSummary';
import { Listing, Platform } from './models/listing';
import { useMemo } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useState } from 'react';
import { useSnackbar } from './utils/error-toast-provider';
import { useCurrentSeller } from './contexts/sellerContext';
import { Add, Download, HideImageRounded, Search } from '@mui/icons-material';
import { SpeedDialTooltipOpen } from './SpeedDialTooltipOpen';
import { VendoraEmailForm } from './VendoraEmailForm';
import { SellerPlatformId } from './models/seller';
import { CrossListingStatus } from './components/CrossListingStatus';
import olxLogo from './icons/olx-logo.png';
import vendoraLogo from './icons/vendora-logo.png';
import { InfiniteList } from './InfiniteList';
import { PaginatedResponse } from './models/paginated-response';
import { useDebounce } from 'use-debounce';

type DashboardFilter = 'activeInOlx';

export default function Dashboard() {
  const vendoraConnectionColorMap: { [key in SellerPlatformId['status']]?: 'green' | 'grey' | 'orange' } = {
    'connected': 'green',
    'none': 'grey',
    'pending': 'orange',
  };

  const vendoraConnectionStatusMap: { [key in SellerPlatformId['status']]?: string } = {
    'connected': 'Свързан',
    'pending': 'В процес на свързване'
  };

  const [filters, setFilters] = useState<DashboardFilter[]>([]);
  const [vendoraDialogOpen, setVendorDialogOpen] = useState(false);

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const currentSeller = useCurrentSeller();

  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText, 1000);

  const getFiltersAndSearch = () => [
    ['filters', filters.length > 0 ? 'activeInOlx' : ''],
    ['search', debouncedSearchText]
  ];

  const fetchListings = async ({ pageParam = 0 }) => {
    const emptyResponse: PaginatedResponse<Listing> = { data: [], offset: undefined };
    const response = await createAuthRequest<PaginatedResponse<Listing>>('listings', 'GET')(undefined, [
      ['page', pageParam.toString()],
      ...getFiltersAndSearch()
    ]);
    return response ?? emptyResponse;
  };

  const listingsCountQuery = useQuery({
    queryKey: ['listings/count', filters, debouncedSearchText],
    queryFn: () => createAuthRequest<number>('listings/count', 'GET')(undefined, [...getFiltersAndSearch()]),
  });

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['listings', 'paginated', filters, debouncedSearchText],
    queryFn: fetchListings,
    getNextPageParam: (lastPage) => lastPage.data.length === 0 ? undefined : lastPage?.offset,
    initialPageParam: 0
  });

  const syncListingsQuery = useMutation({
    mutationFn: () => createAuthRequest<Listing[]>('marketplaces/all/listings', 'PUT')(undefined, undefined),
    onSuccess: (data: Listing[] | undefined) => {
      showSnackbar('Обявите бяха успешно синхронизирани', 'success');
      queryClient.setQueriesData({ queryKey: ['listings', 'paginated'] }, (oldData: { pages: { data: Listing[] }[] } | undefined) => {
        if (!data) {
          return data;
        }
        const syncedListingsMap = data.reduce((acc: { [key: string]: Listing }, listing: Listing) => {
          acc[listing._id] = listing;
          return acc;
        }, {}) ?? {};
        oldData?.pages.forEach((page) => {
          page.data = page.data.map((listing) => syncedListingsMap[listing._id] ?? listing)
        });
        return oldData;
      });
    }
  });

  const shouldShowOLXLoginButton = useMemo(() =>
    !currentSeller?.platformCredentials?.[Platform.Olx],
    [currentSeller]);

  const olxLoginRequest = useMutation({
    mutationFn: async () => {
      const response = await createAuthRequest<{ url: string }>('olx-auth/login', 'POST')();
      if (!!response) {
        window.location.assign(response.url);
      }
    }
  });

  const handleSwitchChange = (isChecked: boolean) => {
    setFilters(isChecked ? ['activeInOlx'] : []);
  };

  const listings = data?.pages.flatMap((response) => response?.data ?? []) ?? [];

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const getRowHeight = (listings: Listing[], index: number, isSmallScreen: boolean) => {
    if (!listings[index]) {
      return isSmallScreen ? 248 : 200; // skeleton height
    }
    const listing = listings[index];

    if (isSmallScreen && listing.images.length === 0) {
      return 176;
    } else if (isSmallScreen && listing.title.length > 40) {
      return 380;
    } else if (isSmallScreen) {
      return 330;
    }
    return 200;
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: 'background.default', color: 'text.primary' }}>
      <Stack sx={{ width: { xs: '95%', md: '80%' }, maxWidth: '1600px', overflowX: 'hidden' }} direction='column' spacing={2} alignItems='center'>
        <Stack
          width={{ sm: '95%' }}
          direction='column'
          spacing={5}
          justifyContent='start'
          alignItems='center'>
          <Stack
            width='100%'
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent='start'
            alignItems='center'
            spacing={5}>
            <Typography
              component="h1"
              variant="h3"
              sx={{ fontWeight: 600 }}>{listingsCountQuery.data ?? 'Всички'} {listingsCountQuery.data === 1 ? 'обява' : 'обяви'}</Typography>

            {!!currentSeller &&
              <Stack justifySelf='end' display='flex' direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                {currentSeller.externalIds?.vendora ? <CrossListingStatus iconPosition='right' crossListing={{
                  platformLogo: vendoraLogo,
                  statusColor: vendoraConnectionColorMap[currentSeller.externalIds.vendora.status] ?? 'grey',
                  statusText: vendoraConnectionStatusMap[currentSeller.externalIds.vendora.status] ?? 'Не е свързан'
                }} />
                  : <Fab
                    variant='extended'
                    onClick={() => setVendorDialogOpen(true)}
                    color='primary'
                  >
                    <Avatar
                      src={vendoraLogo}
                      sx={{ width: 24, height: 24, mr: 1 }}
                      variant='rounded'
                    />
                    <Typography variant='body2'>Свържи Vendora профила си</Typography>
                  </Fab>
                }
                {currentSeller.platformCredentials[Platform.Olx] ?
                  <CrossListingStatus iconPosition='right' crossListing={{
                    platformLogo: olxLogo,
                    statusColor: 'green',
                    statusText: 'Свързан'
                  }} />
                  : <Fab
                    variant='extended'
                    onClick={() => olxLoginRequest.mutate()}
                  >
                    <Avatar
                      src={olxLogo}
                      sx={{ width: 24, height: 24, mr: 1 }}
                      variant='rounded'
                    />
                    <Typography variant='body2'>Свържи OLX профила си</Typography>
                  </Fab>
                }
              </Stack>
            }
          </Stack>

          <Stack display={{ xs: 'none', sm: 'flex' }} spacing={1} direction={{ xs: 'column', sm: 'row' }} alignItems='center' justifyContent='start'>
            <Fab
              title='Създай обява'
              variant='extended'
              onClick={() => { navigate('/create'); navigate(0); }}
            >
              <Add sx={{ mr: 1 }} />
              <Typography variant='body2'>Създай обява</Typography>
            </Fab>
            <Fab
              variant='extended'
              color='primary'
              onClick={() => shouldShowOLXLoginButton ?
                olxLoginRequest.mutate() :
                navigate('/listings/import')}
            >
              <Download sx={{ mr: 1 }} />
              <Typography variant='body2'>Добави обявите си от OLX</Typography>
            </Fab>
            <Fab color='info'
              variant='extended'
            >
              <Typography variant='caption'>Скрий неактивните обяви в OLX</Typography>
              <Switch onChange={(_, checked) => handleSwitchChange(checked)}></Switch>
            </Fab>
            <Fab
              variant='extended'
              onClick={() => syncListingsQuery.mutate()}
            >
              <RefreshIcon sx={{ mr: 1 }}></RefreshIcon>
              <Typography variant='caption'>Обнови обявите</Typography>
            </Fab>
          </Stack>
        </Stack>

        <Box sx={{ display: 'flex', width: '75%', alignItems: 'flex-end' }}>
          <Search sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          <TextField
            fullWidth
            onChange={(e) => setSearchText(e.target.value)}
            label="Търси обяви"
            variant="standard" />
        </Box>

        <InfiniteList
          hasNextPage={hasNextPage}
          isNextPageLoading={isFetching || isFetchingNextPage}
          items={listings}
          loadNextPage={async () => { fetchNextPage() }}
          skeleton={<Box height={137}>
            <ListingItemSummarySkeleton />
          </Box>}
          row={(item: Listing) => <ListingItemSummary listing={item} key={item._id} />}
          rowHeight={(index: number) => getRowHeight(listings, index, isSmallScreen)}
        />

        <Dialog onClose={() => setVendorDialogOpen(false)} open={vendoraDialogOpen}>
          <VendoraEmailForm
            existingEmail={currentSeller?.externalIds?.vendora?.email}
            onEmailUpdated={() => { setVendorDialogOpen(false); navigate(0); }}
          />
        </Dialog>
      </Stack >
      <Stack
        display={{ xs: 'flex', sm: 'none' }}
      >
        <SpeedDialTooltipOpen
          actions={[
            { icon: <Add />, name: 'Създай обява', onClick: () => navigate('/create') },
            {
              icon: <Download />, name: 'Добави обявите си от OLX', onClick: () => shouldShowOLXLoginButton ?
                olxLoginRequest.mutate() :
                navigate('/listings/import')
            },
            { icon: <HideImageRounded />, name: 'Скрий некативните обяви от OLX', onClick: () => handleSwitchChange(true) },
            { icon: <RefreshIcon />, name: 'Обнови обявите от OLX', onClick: () => syncListingsQuery.mutate() },
          ]}
        />
      </Stack>
    </Box>
  )
}