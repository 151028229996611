import { useContext, useEffect, useState } from 'react';
import { TreeItem, Node, getSubtree } from './models/tree';
import createAuthRequest from './utils/createAuthRequest';
import { useQuery } from '@tanstack/react-query';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { TreeVisualizer } from './TreeVisualizer';
import { OlxCategoriesContext, VendoraCategoriesContext } from './contexts/categoriesContext';
import { Platform } from './models/listing';

type Props = {
  listingTitle: string;
  isEditing: boolean;
  onCategorySelected: (selectedCategory: Node<TreeItem>) => void;
  preselectedCategoryId?: string;
  marketplace: Platform;
  suggestionsEndpoint?: string;
};

export const CategorySelector = ({ listingTitle, isEditing, onCategorySelected, preselectedCategoryId, suggestionsEndpoint, marketplace }: Props) => {
  const categorySuggestionQuery = useQuery({
    queryKey: ['categories', listingTitle],
    queryFn: () => listingTitle.length === 0 || !suggestionsEndpoint ?
      { suggestion: undefined } :
      createAuthRequest<{ suggestion: string | undefined }>(suggestionsEndpoint, 'GET')(undefined, [['title', listingTitle]]),
  });

  const [userHasSelectedCategory, setUserHasSelectedCategory] = useState<boolean>(false);

  const categories = useContext(marketplace === Platform.Olx ? OlxCategoriesContext : VendoraCategoriesContext);

  const [suggestedCategory, setSuggestedCategory] = useState<Node<TreeItem> | undefined>();
  const [selectedCategory, setSelectedCategory] = useState<Node<TreeItem> | undefined>();

  useEffect(() => {
    if (!preselectedCategoryId || !categories) {
      return;
    }
    const newCategory = getSubtree(categories, preselectedCategoryId)?.root;
    if (!!newCategory) {
      updateCategory({ category: newCategory });
    }
  }, [categories]);

  // Set the suggested category every time a request is completed
  // If no category is selected at the time, set the suggested category
  useEffect(() => {
    if (categorySuggestionQuery.data?.suggestion === undefined || categories === undefined || isEditing || userHasSelectedCategory) {
      return;
    }
    const categorySuggestion = getSubtree(categories, categorySuggestionQuery.data.suggestion.toString())?.root;
    setSuggestedCategory(categorySuggestion);
    if (!selectedCategory && !!categorySuggestion && !preselectedCategoryId) {
      updateCategory({ category: categorySuggestion });
    }
  }, [categorySuggestionQuery.data]);

  const updateCategory = ({ category, userSelection = false }: { category: Node<TreeItem>, userSelection?: boolean }) => {
    setUserHasSelectedCategory(userSelection);
    setSelectedCategory(category);
    onCategorySelected(category);
  };

  return !categories ?
    <><CircularProgress color='primary' /></> :
    <>
      {
        !userHasSelectedCategory && suggestedCategory && selectedCategory?.data.id !== suggestedCategory.data.id &&
        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='start'>
          <Typography>Препоръчана категория: {suggestedCategory.data.name}</Typography>
          <Button onClick={() => updateCategory({ category: suggestedCategory, userSelection: true })}>
            Избери
          </Button>
        </Box>
      }
      {
        categories &&
        <TreeVisualizer
          tree={categories}
          onItemSelected={(category) => updateCategory({ category, userSelection: true })}
          selectedCategory={selectedCategory}
          selectedCategoryLabel='категория'
        />
      }
    </>
}