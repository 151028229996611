import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { useState } from 'react';

type Props = {
  actions: { icon: React.ReactNode, name: string, onClick: () => void }[]
};

export const SpeedDialTooltipOpen = ({ actions }: Props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Backdrop open={open} sx={{ width: '100%' }} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: 'fixed', bottom: 30, right: 30 }}
        FabProps={{ color: 'secondary' }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            sx={{
              '& span.MuiSpeedDialAction-staticTooltipLabel': {
                width: `${action.name.length * 0.5}rem`,
                textAlign: 'center',
                fontSize: '.9rem',
              }
            }}
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipPlacement='left'
            tooltipOpen
            onClick={() => { action.onClick(); handleClose(); }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}