import { Listing, OlxStatus, VendoraStatus, Platform } from '../models/listing';
import { Avatar, Badge, Tooltip, Typography } from '@mui/material';
import olxLogo from '../icons/olx-logo.png';
import vendoraLogo from '../icons/vendora-logo.png';
import { Box } from '@mui/material';
import { timeDifference } from '../utils/time-difference';
import { Circle } from '@mui/icons-material';

const OlxToColorStatusMap: { [key in OlxStatus]?: string } = {
  [OlxStatus.Active]: 'green',
  [OlxStatus.New]: 'orange',
  [OlxStatus.Disabled]: 'orange'
};

const getTranslatedOlxStatus = (status?: OlxStatus): string => {
  switch (status) {
    case 'active':
      return 'Активна';
    case 'new':
      return 'Нова';
    case 'limited':
      return 'Изчаква плащане';
    case 'removed_by_user':
      return 'Деактивирана';
    case 'moderated':
      return 'Модерирана';
    case 'removed_by_moderator':
      return 'Деактивирана';
    case 'disabled':
      return 'Изчакваща'
    default:
      return 'Несподелена';
  }
}

const VendoraColorStatusMap: { [key in VendoraStatus]?: string } = {
  ['pending']: 'orange',
  ['pending_deletion']: 'orange',
  ['action_required']: 'red',
  ['import_error']: 'red',
  ['discoverable']: 'green',
  ['deleted_in_vendora']: 'grey',
  ['deleted']: 'grey',
  ['not_shared']: 'grey',
};

const getTranslatedVendoraStatus = (status?: VendoraStatus): string => {
  switch (status) {
    case 'pending':
      return 'Обработва се';
    case 'deleted':
      return 'Изтрита';
    case 'discoverable':
      return 'Активна';
    case 'import_error':
      return 'Грешка при импорт';
    case 'action_required':
      return 'Изисква се действие';
    case 'deleted_in_vendora':
      return 'Изтрита през Vendora';
    case 'pending_deletion':
      return 'Маркирана за изтриване'
    default:
      return 'Несподелена';
  }
}

type CrossListingData = {
  platformLogo?: string;
  statusColor: string;
  statusText?: string;
  lastSynced?: number;
  unreadMessagesCount?: number;
}

export const CrossListingStatus = ({ crossListing, iconPosition }: { crossListing: CrossListingData, iconPosition: 'right' | 'bottom' }) => {
  const { platformLogo, statusColor, lastSynced } = crossListing;
  const lastSyncedRelative = !!lastSynced ? `последно обновена ${timeDifference(Date.now(), lastSynced)}` : undefined;
  return (
    <Box width={80} display='flex' flexDirection={'column'} alignItems={'center'} columnGap={2}>

      <Box display='flex' flexDirection={iconPosition === 'right' ? 'row' : 'column'} gap={2} alignItems='center'>
        {platformLogo ?
          <Tooltip placement='left' title={crossListing.unreadMessagesCount ? `${crossListing.unreadMessagesCount} непрочетени съобщения` : undefined}>
            <Badge badgeContent={crossListing.unreadMessagesCount} color='error'>
              <Avatar
                src={platformLogo ?? 'grey'}
                variant='rounded'
                sx={{ width: 40, height: 40 }}
              />
            </Badge>
          </Tooltip> :
          <Circle sx={{ width: 40, height: 40, opacity: 0 }}></Circle>
        }
        {platformLogo &&
          <Tooltip title={crossListing.statusText}>
            <Circle sx={{ color: statusColor }}></Circle>
          </Tooltip>
        }
      </Box>
      {
        lastSyncedRelative &&
        <Typography variant='caption' textAlign='center'>
          {lastSyncedRelative}
        </Typography>
      }
    </Box >
  );
}

export const TextCrossListingStatus = ({ listing, platform }: { listing: Listing, platform: Platform }) => {
  if (platform === Platform.Main) {
    return null;
  }
  //display status name and color for platform
  const statusText = platform === Platform.Olx
    ? getTranslatedOlxStatus(listing.crossListings?.olx?.status)
    : getTranslatedVendoraStatus(listing.crossListings?.vendora?.status);

  const status = listing?.crossListings?.[platform]?.status ?? 'not_shared';
  const statusColor = platform === Platform.Olx ? OlxToColorStatusMap[status as OlxStatus] : VendoraColorStatusMap[status as VendoraStatus];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
      <Typography variant='body2'>
        {`Статус: ${statusText}`}
      </Typography>
      <Circle sx={{ width: 20, height: 20, color: statusColor }}></Circle>
    </Box>
  );
}

export const ItemCrossListingStatus = ({ listing }: { listing: Listing }) => {
  const olxListing = listing.crossListings?.olx;
  const vendoraListing = listing.crossListings?.vendora;

  return <Box display='flex' gap={1} alignItems='start'>
    {
      <CrossListingStatus iconPosition='bottom' crossListing={{
        platformLogo: olxListing ? olxLogo : undefined,
        statusColor: (listing.externalIdentifiers?.olx && !!olxListing?.status &&
          OlxToColorStatusMap[olxListing.status]) || 'grey',
        statusText: olxListing ? getTranslatedOlxStatus(olxListing?.status) : undefined,
        lastSynced: listing.externalIdentifiers?.olx && olxListing?.lastSynced ? olxListing.lastSynced : undefined,
        unreadMessagesCount: listing.crossListings?.olx?.unreadMessagesCount,
      }} />
    }
    {
      !!vendoraListing &&
      <CrossListingStatus iconPosition='bottom' crossListing={{
        platformLogo: vendoraLogo,
        statusColor: (!!vendoraListing.status && VendoraColorStatusMap[vendoraListing.status]) || 'grey',
        statusText: getTranslatedVendoraStatus(vendoraListing?.status),
        lastSynced: vendoraListing?.lastSynced,
      }} />
    }
  </Box>
}
