export type NodeIdentifiers = {
  id: string;
  parent_id?: string;
};

export interface Node<T extends NodeIdentifiers> {
  data: T;
  children?: { [key: number | string]: Tree<T> }
};

export type TreeItem = {
  id: string,
  name: string;
  parent_id?: string;
  municipality?: string;
};

export class Tree<T extends NodeIdentifiers> {
  root: Node<T>;

  constructor(root?: Node<T>) {
    this.root = !!root
      ? root
      : {
        data: {
          id: '0',
          parent_id: undefined
        } as T,
        children: {}
      };
  };
};

/**
 * Returns the subtree starting at the given `id`
 * @param id - the id to search for
 * @returns the subtree starting at `id` or `undefined` if the id is not present in the tree
 */
export const getSubtree = <T extends NodeIdentifiers>(tree: Tree<T>, id?: string): Tree<T> | undefined => {
  const { data, children } = tree.root;
  if (id === data?.id) {
    return tree;
  }

  if (Object.values(children ?? {})?.length === 0) {
    return undefined
  }

  const result = Object.values(children ?? {})
    ?.map(child => getSubtree(child, id))
    .filter(subtree => subtree !== undefined);

  if ((result?.length ?? 0) > 0) {
    return result?.[0];
  }
};

/**
 * Get nodes at a specific level in the tree, starting from the root
 * @param tree the input tree
 * @param level the level wanted (root is level 0)
 * @returns
 */
export const getLevel = <T extends NodeIdentifiers>(tree: Tree<T>, level: number): Node<T>[] => {
  if (level < 0) {
    return [];
  }

  if (level === 0) {
    return [tree.root];
  }
  return Object.values(tree.root.children ?? {})?.flatMap(child => getLevel(child, level - 1)) ?? [];
};
