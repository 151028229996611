import { OutputType as OutputAttributeValue } from '../AttributeField';

export interface NumericAttribute extends GenericAttributeDeclaration {
  code: string;
  type: 'number';
  value: 'single';
  min: number;
  max: number;
}

export interface InputAttribute extends GenericAttributeDeclaration {
  code: string;
  type: 'string';
  value: 'single';
}

export interface SelectAttribute extends GenericAttributeDeclaration {
  code: string;
  type: 'string';
  value: 'single';
  options: Option[];
}

export interface MultiSelectAttribute extends GenericAttributeDeclaration {
  code: string;
  type: 'string';
  value: 'multiple';
  options: Option[];
}

export type AttributeDeclaration = NumericAttribute | InputAttribute | SelectAttribute | MultiSelectAttribute;

export const isNumericAttribute = (attribute: GenericAttributeDeclaration): attribute is NumericAttribute => {
  return attribute.type === 'number';
}

export const isInputAttribute = (attribute: GenericAttributeDeclaration): attribute is InputAttribute => {
  return attribute.type === 'string' && !attribute.options && attribute.value === 'single';
}

export const isSelectAttribute = (attribute: GenericAttributeDeclaration): attribute is SelectAttribute | MultiSelectAttribute => {
  return attribute.type === 'string' && !!attribute.options;
}

export const isSingleSelectAttribute = (attribute: GenericAttributeDeclaration): attribute is SelectAttribute => {
  return isSelectAttribute(attribute) && attribute.value === 'single';
}

export const isMultiSelectAttribute = (attribute: GenericAttributeDeclaration): attribute is MultiSelectAttribute => {
  return isSelectAttribute(attribute) && !isSingleSelectAttribute(attribute);
}

export interface GenericAttributeDeclaration {
  code: string;
  label: string;
  required: boolean;
  unit?: string;
  type: AttributeType;
  value: AttributeValue;
  options?: Option[];
  min?: number;
  max?: number;
}

type AttributeValue = 'single' | 'multiple';
type AttributeType = 'string' | 'number';
interface Option {
  code: string;
  label: string;
}
export interface AttributeForSubmission {
  code: string;
  value: string | undefined;
  values: (string | undefined)[] | undefined;
}

export type SingleDiscreteValue = { label: string, code: string };
export type DiscreteValues = SingleDiscreteValue[];
export type DiscreteValue = SingleDiscreteValue | DiscreteValues | null;

/**
 * Converts attributes that are filled in expanda to OLX attributes that can be submitted with a listing
 */
export const convertAttributesForSubmission = (attributes: { [key: string]: OutputAttributeValue }): AttributeForSubmission[] =>
  Object.entries(attributes).map(([key, value]) => ({
    code: key,
    value: !Array.isArray(value) ? value : undefined,
    values: Array.isArray(value) ? value : undefined
  }));

export const convertAttributesForVendora = (attributes: { [key: string]: OutputAttributeValue }): { [key: string]: string | string[] } => {
  return Object.entries(attributes).reduce((result, [key, value]) => {
    if (Array.isArray(value)) {
      return { ...result, [key]: value };
    }
    return { ...result, [key]: value };
  }, {});
}