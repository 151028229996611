export const timeDifference = (current: number, previous: number): string => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = current - previous;

  let result: number;
  let unit: string;

  if (elapsed < msPerMinute) {
    result = Math.round(elapsed / 1000);
    unit = result === 1 ? 'секунда' : 'секунди';
  }
  else if (elapsed < msPerHour) {
    result = Math.round(elapsed / msPerMinute);
    unit = result === 1 ? 'минута' : 'минути';
  }
  else if (elapsed < msPerDay) {
    result = Math.round(elapsed / msPerHour);
    unit = result === 1 ? 'час' : 'часа';
  }
  else if (elapsed < msPerMonth) {
    result = Math.round(elapsed / msPerDay);
    unit = result === 1 ? 'ден' : 'дни';
  }
  else if (elapsed < msPerYear) {
    result = Math.round(elapsed / msPerMonth);
    unit = result === 1 ? 'месец' : 'месеца';
  }
  else {
    result = Math.round(elapsed / msPerYear);
    unit = result === 1 ? 'година' : 'години';
  }

  if (unit === 'секунда' || unit === 'секунди') {
    return 'току що';
  }

  return `преди ${result} ${unit}`;
}