import { Close, Download } from '@mui/icons-material'
import { Button, ButtonGroup, Checkbox, Dialog, Grid, IconButton, Paper, Stack, useTheme } from '@mui/material'
import { useEffect, useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  existingImageUrls: string[];
  initialSelectedImageUrl: string;
  listingId: string;
}

export const ImagePreviewDialog = ({ isOpen, onClose, existingImageUrls, initialSelectedImageUrl, listingId }: Props) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(initialSelectedImageUrl);
  const [currentIndex, setCurrentIndex] = useState(existingImageUrls.indexOf(initialSelectedImageUrl));

  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  const handleSelectAll = () => {
    setSelectedImages(selectedImages.length === existingImageUrls.length ? [] : existingImageUrls);
  };

  const handleCheckboxChange = (url: string) => {
    setSelectedImages((prev) =>
      prev.includes(url) ? prev.filter((image) => image !== url) : [...prev, url]
    );
  };

  const handleImageClick = (url: string) => {
    setSelectedImage(url);
    setCurrentIndex(existingImageUrls.indexOf(url));
  };

  const closePreview = () => {
    setSelectedImage(null);
    setSelectedImages([]);
    onClose();
  };

  useEffect(() => {
    if (!!initialSelectedImageUrl) {
      setSelectedImage(initialSelectedImageUrl);
      setCurrentIndex(existingImageUrls.indexOf(initialSelectedImageUrl));
    }
  }, [initialSelectedImageUrl]);

  const handleKeyDown = (event: any) => {
    if (!selectedImage) return;

    if (event.key === 'ArrowDown' && currentIndex < existingImageUrls.length - 1) {
      const nextIndex = currentIndex + 1;
      setSelectedImage(existingImageUrls[nextIndex]);
      setCurrentIndex(nextIndex);
    } else if (event.key === 'ArrowUp' && currentIndex > 0) {
      const prevIndex = currentIndex - 1;
      setSelectedImage(existingImageUrls[prevIndex]);
      setCurrentIndex(prevIndex);
    } else if (event.key === 'Escape') {
      closePreview();
    }
  };

  useEffect(() => {
    if (selectedImage) {
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }
  }, [selectedImage, currentIndex, existingImageUrls]);

  const theme = useTheme();

  const downloadFile = async (url: string, index: number) => {
    try {
      // Fetch the file as a blob
      const response = await fetch(url);
      const blob = await response.blob();

      // Create a temporary object URL
      const blobUrl = URL.createObjectURL(blob);

      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${listingId}-expanda-download-${index}`;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const download = () => {
    selectedImages.forEach(async (url, index) => await downloadFile(url, index));
  }

  const removeLogos = () => {
    // TODO
  }

  return <Dialog
    open={isOpen}
    onClose={closePreview}
    maxWidth={false}
  >
    <IconButton
      sx={{
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        color: theme.palette.text.primary,
      }}
      onClick={closePreview}
    >
      <Close />
    </IconButton>
    <Grid
      className='image-preview-dialog-main-grid'
      container
      sx={{
        height: '100%',
        width: '100%',
        //maxWidth: '50%',
        backgroundColor: theme.palette.background.default,
      }}
    >
      {/* Thumbnails Section */}
      <Grid
        item
        xs={3}
        component={Paper}
        square
        sx={{
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(2),
          overflowY: 'auto',
          height: '100%',
        }}
      >
        <Stack direction='column'>
          <Button
            variant="contained"
            size="small"
            onClick={handleSelectAll}
          >
            Избери всички
          </Button>
          <ButtonGroup sx={{ marginTop: 1 }} orientation='vertical' variant="outlined" aria-label="Basic button group">
            <Button
              onClick={() => download()}
              disabled={selectedImages.length === 0}
            >
              <>
                Изтегли
                <Download />
              </>
            </Button>
            <Button
              onClick={() => removeLogos()}
              disabled={true}
            >
              Премахни лога
            </Button>
          </ButtonGroup>
        </Stack>
        {existingImageUrls.map((url, index) =>
          <Stack direction='row' justifyContent='start' alignItems='center'
            sx={{
              marginBottom: theme.spacing(2),
              border: selectedImage === url ? `2px solid ${theme.palette.primary.main} ` : 'none',
              cursor: 'pointer',
              width: '100%',
              marginTop: 3,
              overflow: 'hidden',
            }}
          >
            <Checkbox
              checked={selectedImages.includes(url)}
              onChange={() => handleCheckboxChange(url)}
              color="primary"
              sx={{ paddingTop: 3, paddingBottom: 3, borderRadius: 0 }}
            />
            <Paper
              key={index}
              sx={{
                width: '100%',
                cursor: 'pointer',
                padding: 1
              }}
              onClick={() => handleImageClick(url)}
            >
              <img
                src={url}
                alt={`Thumbnail ${index + 1} `}
                style={{
                  width: '100%',
                  height: '100px',
                  objectFit: 'contain',
                  minWidth: '100px',
                  minHeight: '100px'
                }}
              />
            </Paper>
          </Stack>
        )}
      </Grid>

      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          overflow: 'hidden',
          backgroundColor: theme.palette.background.default,
        }}
      >

        {selectedImage && (
          <img
            src={selectedImage}
            alt="Preview"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              width: '1000px',
              height: '700px',
              objectFit: 'contain', // Ensures aspect ratio
            }}
          />
        )}
      </Grid>
    </Grid>
  </Dialog>

}