import { ReactNode } from 'react'
import { CurrentSellerProvider } from './sellerContext'
import { OlxCategoriesProvider, VendoraCategoriesProvider } from './categoriesContext'

export const AuthenticatedUserContext = ({ children }: { children: ReactNode }) => {
  return <CurrentSellerProvider>
    <OlxCategoriesProvider>
      <VendoraCategoriesProvider>
        {children}
      </VendoraCategoriesProvider>
    </OlxCategoriesProvider>
  </CurrentSellerProvider>;
};