import { AttributeForSubmission } from './category-specific-attribute';
import { RemoteImage } from './image';

export interface Contact {
  name: string;
  phone?: string;
}

export interface Listing {
  _id: string;
  title: string;
  description: string;
  price: number;
  images: RemoteImage[];
  category: string;
  attributes: AttributeForSubmission[];
  location: {
    cityId: string;
    districtId?: string;
  };
  contact: Contact;
  externalIdentifiers?: { [key in Platform]: string | number };
  crossListings?: {
    olx?: OlxListing;
    vendora?: VendoraListing;
  };
  jobs?: [{
    type: 'remove-logo';
    status: | 'in-progress' | 'completed' | 'failed';
  }]
}

export interface ListingCreationData {
  title: string;
  description: string;
  price: number;
  category: string;
  attributes: AttributeForSubmission[],
  location: {
    cityId: string;
    districtId?: string;
  }
}

export interface ListingUpdateData extends ListingCreationData {
  modifiedImages?: {
    platform: Platform;
    images: RemoteImage[];
  }
  crossListings?: {
    [key in Platform]?: ThirdPartyListing | VendoraListing | OlxListing
  };
}

export enum Platform {
  Vendora = 'vendora',
  Olx = 'olx',
  Main = 'main'
}

export enum OlxStatus {
  Active = 'active',
  New = 'new',
  Limited = 'limited',
  RemovedByUser = 'removed_by_user',
  Outdated = 'outdated',
  Unconfirmed = 'unconfirmed',
  Unpaid = 'unpaid',
  Moderated = 'moderated',
  Blocked = 'blocked',
  Disabled = 'disabled',
  RemovedByModerator = 'removed_by_moderator'
}

export type ThirdPartyListing = {
  title: string;
  description: string;
  price: number;
  photos: string[];
  lastSynced?: number;
}

export type OlxListing = {
  title: string;
  description: string;
  price: number;
  images: RemoteImage[];
  category: string;
  attributes: AttributeForSubmission[];
  location: {
    cityId: string;
    districtId?: string;
  };
  contact: Contact;
  status?: OlxStatus;
  unreadMessagesCount?: number;
  lastSynced?: number;
  url?: string;
};

export type VendoraStatus = 'pending' | 'discoverable' | 'action_required' | 'not_shared' | 'deleted' | 'import_error' | 'deleted_in_vendora' | 'pending_deletion';

export type VendoraListing = {
  id?: string;
  title: string;
  description: string;
  price: number;
  category: string;
  attributes: VendoraAttributes;
  images: RemoteImage[];
  lastSynced?: number;
  status?: VendoraStatus;
  url?: string;
  markedForExport: boolean;
};

export type VendoraAttributes = { [key: string]: string | string[] };

export const getEmptyListing = (): Listing => ({
  _id: '',
  title: '',
  description: '',
  price: 0,
  images: [],
  category: '',
  attributes: [],
  location: {
    cityId: ''
  },
  contact: {
    name: ''
  }
});