import { PropsWithChildren, useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function AppTheme(props: PropsWithChildren & { mode: 'light' | 'dark' }) {
  const theme = useMemo(() => createTheme({
    typography: {
      fontFamily: "'Roboto', 'sans-serif'",
      fontSize: 16,
      fontWeightRegular: 400,
      fontWeightBold: 400,
      fontWeightMedium: 400
    },
    palette: {
      mode: props.mode,
      primary: {
        main: props.mode === 'light' ? '#41436a' : '#903cae',
      },
      secondary: {
        main: '#fe9677'
      },
      background: props.mode === 'dark'
        ? { default: '#212121', paper: '#1d1d1d' } // Set custom background colors for dark mode
        : {}, // Leave it empty for light mode (it will use the default MUI values)
    },
  }), [props.mode]);

  return <ThemeProvider theme={theme}>
    {props.children}
  </ThemeProvider>
}