import { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import createAuthRequest from '../utils/createAuthRequest';
import { useQuery } from '@tanstack/react-query';
import { Seller } from '../models/seller';

const CurrentSellerContext = createContext<Seller | undefined>(undefined);

export const CurrentSellerProvider = ({ children }: { children: ReactNode }) => {
  const sellerQuery = useQuery({
    queryKey: ['seller'],
    queryFn: () => createAuthRequest<Seller>('sellers/current', 'GET')()
  });

  const [seller, setSeller] = useState<Seller | undefined>();

  useEffect(() => {
    if (sellerQuery.data) {
      setSeller(sellerQuery.data);
    }
  }, [sellerQuery.data]);

  return (
    <CurrentSellerContext.Provider value={seller}>
      {children}
    </CurrentSellerContext.Provider>
  );
};

export const useCurrentSeller = () => {
  return useContext(CurrentSellerContext);
};