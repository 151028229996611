import { useState, MouseEvent, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { StyledMenuLink } from './StyledLink';
import { auth } from '../App';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Switch } from '@mui/material';
import { ModeNight, WbSunny } from '@mui/icons-material';

// TODO: Дефиниране на const ROUTES, откъдето да се ползват консистентно в приложението
export type MenuLink = { title: string, href: string, reload?: boolean };
const MENU_LOGGED_OUT: MenuLink[] = [{ title: 'Вход/Регистрация', href: '/login' }];
const MENU_LOGGED_IN: MenuLink[] = [
  { title: 'Всички обяви', href: '/' },
  { title: 'Създай обява', href: '/create', reload: true },
  { title: 'Изход', href: '/logout' }
];

export default function Header({ themeMode, toggleThemeMode }: { themeMode: 'light' | 'dark', toggleThemeMode: () => void }) {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [user, loading] = useAuthState(auth);
  const [currentMenu, setCurrentMenu] = useState<MenuLink[]>([]);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    if (!loading) {
      setCurrentMenu(user !== null ? MENU_LOGGED_IN : MENU_LOGGED_OUT);
    }
  }, [user, loading]);

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <StorefrontIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 600,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            expanda
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {currentMenu.map(item => (
                <MenuItem key={item.title}>
                  <StyledMenuLink to={item.href} reloadDocument={item.reload}>{item.title}</StyledMenuLink>
                </MenuItem>))}
            </Menu>
          </Box>

          <StorefrontIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            expanda
          </Typography>
          <Switch checkedIcon={<ModeNight sx={{ width: 20, height: 20, color: 'white' }} />} icon={<WbSunny sx={{ width: 20, height: 20 }} />} checked={themeMode === 'dark'} onChange={toggleThemeMode} />
          <Box sx={{ flexGrow: 0, marginLeft: 'auto', display: { xs: 'none', md: 'flex', gap: '20px' } }}>
            {currentMenu.map((item) => (
              <StyledMenuLink
                reloadDocument={item.reload}
                to={item.href}
                key={item.title}
                sx={{ paddingX: 2, color: 'inherit' }}
              >
                {item.title}
              </StyledMenuLink>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar >
  );
}